export const lastTime = function (date) {
  //  1    2    3    4    5    6    7    8    9   10    11   12月
  var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var strYear = date.getFullYear();
  var strDay = date.getDate();
  var strMonth = date.getMonth() + 1;
  //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
  if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
    daysInMonth[2] = 29;
  }
  if (strMonth - 1 === 0) {
    //二、解决跨年问题
    strYear -= 1;
    strMonth = 12;
  } else {
    strMonth -= 1;
  }
  //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
  strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

  if (strMonth < 10) {
    //给个位数的月、日补零
    strMonth = "0" + strMonth;
  }
  if (strDay < 10) {
    strDay = "0" + strDay;
  }
  var datastr = strYear + "-" + strMonth + "-" + strDay;
  return datastr;
}

export function formatDate(date, format) {
  format = format || "YYYY-MM-DD hh:mm:ss";   //第一个参数不填时，使用默认格式
  let ret, renum;
  const opt = {
    "Y": date.getFullYear().toString(), // 年
    "M": (date.getMonth() + 1).toString(), // 月
    "D": date.getDate().toString(), // 日
    "h": date.getHours().toString(), // 时
    "m": date.getMinutes().toString(), // 分
    "s": date.getSeconds().toString() // 秒
    // 目前用的是这六种符号,有其他格式化字符需求可以继续添加，值必须转化成字符串
  };
  for (var k in opt) {
    ret = new RegExp("(" + k + "+)").exec(format);
    if (ret) {
      renum = (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")) //根据复数前面是否补零,如“mm”补零，单“m”前面不补零
      format = format.replace(ret[1], renum)  //替换
    };
  };
  return format;
}