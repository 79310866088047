<template>
  <div ref="businessSettlement" class="businessSettlement">
    <div ref="tabs" class="tabs">
      <el-tabs v-model="tab" type="card" @tab-click="handleClick">
        <el-tab-pane label="经营结算" name="1">
          <div ref="explain" class="explain">
            <header><i class="el-icon-warning"></i> 数据说明</header>
            <ol>
              <li>经营结算统计数据均按发车时间统计</li>
              <li>
                订单结算金额=订单金额-申请退/改金额-售后退款金额-平台服务费
              </li>
            </ol>
          </div>
          <div ref="area" class="form-area">
            <el-form
              :inline="true"
              :model="form1"
              class="demo-form-inline"
              label-position="right"
              label-width="85px"
            >
              <el-form-item label="结算周期：">
                <el-date-picker
                  v-model="form1.month"
                  type="month"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  :picker-options="pickerOptions"
                  placeholder="请选择结算周期"
                  :clearable="false"
                />
              </el-form-item>
              <el-form-item label="业务类型：">
                <el-select
                  v-model="form1.businessType"
                  clearable
                  placeholder="请选择业务类型"
                >
                  <el-option
                    v-for="role in businessTypeList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属公司：">
                <companySelect
                  :clearable="true"
                  :company-tree="companyList"
                  :valuename="valuename"
                  :value="value"
                  @getValue="getGroupId1"
                ></companySelect>
              </el-form-item>
              <el-form-item label="状态：">
                <el-select
                  v-model="form1.status"
                  clearable
                  placeholder="请选择状态"
                >
                  <el-option
                    v-for="role in statusList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="queryFun1"
                  >查询</el-button
                >
                <el-button
                  v-if="listFind('导出')"
                  type="primary"
                  size="small"
                  @click="tableExport1"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <Table
              :table-data="tableData1"
              :title-name="titleName1"
              :operation="true"
              :operation-width="150"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    v-if="listFind('查看')"
                    type="text"
                    size="small"
                    sort="primary"
                    @click="tableDetailPopup(scope.scopeRow)"
                    >查看</el-button
                  >
                  <el-button
                    v-if="listFind('确认') && scope.scopeRow.status == 0"
                    type="text"
                    size="small"
                    sort="primary"
                    @click="confirmFun(scope.scopeRow)"
                    >确认</el-button
                  >
                  <el-button
                    v-if="listFind('结算') && scope.scopeRow.status == 1"
                    type="text"
                    size="small"
                    sort="primary"
                    @click="SettlementFun(scope.scopeRow)"
                    >结算</el-button
                  >
                </div>
              </template>
            </Table>
          </div>
          <div ref="pagination" class="pagination">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-size="form1.pageSize"
              :current-page="form1.currentPage"
              @size-change="onSizeChange1"
              @current-change="onCurrentChange1"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="经营明细" name="2">
          <div ref="area" class="form-area">
            <el-form
              :inline="true"
              :model="form2"
              class="demo-form-inline"
              label-position="right"
              label-width="85px"
            >
              <el-form-item label="结算周期：">
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  range-separator="至"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions2"
                  :clearable="false"
                />
              </el-form-item>
              <el-form-item label="所属公司：">
                <companySelect
                  :clearable="true"
                  :company-tree="companyList"
                  :valuename="valuename"
                  :value="value"
                  @getValue="getGroupId2"
                ></companySelect>
              </el-form-item>
              <el-form-item label="业务类型：">
                <el-select
                  v-model="form2.businessType"
                  clearable
                  placeholder="请选择业务类型"
                >
                  <el-option
                    v-for="role in businessTypeList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="queryFun2"
                  >查询</el-button
                >
                <el-button
                  v-if="listFind('导出2')"
                  type="primary"
                  size="small"
                  @click="tableExport2"
                  >导出</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <Table :table-data="tableData2" :title-name="titleName2"> </Table>
          </div>
          <div ref="pagination" class="pagination">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-size="form2.pageSize"
              :current-page="form2.currentPage"
              @size-change="onSizeChange2"
              @current-change="onCurrentChange2"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <TicketPopup
      v-if="ticketShow"
      :row-data-detail="rowDataDetail"
      :company="Company"
      :agent="agent"
      :agent2="agent2"
      @closeFun="ticketShow = false"
    />
  </div>
</template>

<script>
import {
  queryBusinessDetailsPage,
  getCompanyTree,
  queryBusinessStatisticsPage,
  businessStatisticsSettlement,
  businessStatisticsConfirm,
  exportBusinessStatistics,
  exportBusinessDetails,
} from "@/api/lib/api.js";
import { lastTime } from "@/tools/getTime.js";
import moment from "moment";
import companySelect from "@/components/treeSelect/companySelect.vue";
import TicketPopup from "./ticketPopup3/index.vue";
export default {
  components: {
    TicketPopup,
    companySelect,
  },
  data() {
    return {
      tab: "1",
      companyList: [],
      value: null,
      valuename: null,
      total: 0,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      form1: {
        month: moment(new Date()).format("YYYY-MM"),
        businessType: null,
        status: null,
        companyId: null,
        currentPage: 1,
        pageSize: 10,
      },
      agent: "",
      agent2: "",
      Company: "",
      rowDataDetail: undefined,
      ticketShow: false,
      businessTypeList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "定制客运",
        },
        {
          value: 2,
          label: "汽车票",
        },
      ],
      statusList: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "待结算",
        },
        {
          value: 2,
          label: "已结算",
        },
      ],
      tableData1: [],
      titleName1: [
        {
          title: "结算周期",
          props: "statisticsMonth",
          width: 200,
        },
        {
          title: "公司",
          props: "companyName",
          width: 120,
        },
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) =>
            this.businessTypeList.find((i) => i.value == res).label,
        },
        {
          title: "订单数",
          props: "totalOrderNum",
        },
        {
          title: "订单金额",
          props: "totalOrderAmount",
        },
        {
          title: "退/改数",
          props: "refundChangeNum",
        },
        {
          title: "申请退/改金额",
          props: "applyRefundChangeAmount",
          width: 120,
        },
        {
          title: "售后退款金额",
          props: "salesRefundAmount",
          width: 110,
        },
        {
          title: "服务费",
          props: "serviceAmount",
        },
        {
          title: "订单结算金额",
          props: "settlementAmount",
          width: 110,
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) =>
            res !== null
              ? this.statusList.find((i) => i.value == res).label
              : "",
        },
        {
          title: "确认人",
          props: "confirmUser",
        },
        {
          title: "确认时间",
          props: "confirmTime",
          width: 200,
        },
        {
          title: "结算人",
          props: "settlementUser",
        },
        {
          title: "结算时间",
          props: "settlementTime",
          width: 200,
        },
      ],

      pickerOptions2: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      form2: {
        startTime: "",
        endTime: "",
        companyId: null,
        businessType: null,
        currentPage: 1,
        pageSize: 10,
      },
      time: null,
      dealList: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "订单收款",
        },
        {
          value: 2,
          label: "订单退款",
        },
        {
          value: 3,
          label: "售后退款",
        },
      ],
      tableData2: [],
      titleName2: [
        {
          title: "发车时间",
          props: "departureTime",
          width: 200,
        },
        {
          title: "交易时间",
          props: "transactionTime",
          width: 200,
        },
        {
          title: "收支类型",
          props: "incomeExpenseType",
          SpecialJudgment: (res) => (res == 1 ? "收入" : "支出"),
        },
        {
          title: "交易类型",
          props: "transactionType",
          SpecialJudgment: (res) =>
            res ? this.dealList.find((i) => i.value == res).label : "",
        },
        {
          title: "订单编号",
          props: "orderId",
          width: 200,
        },
        {
          title: "票号",
          props: "childOrderId",
          width: 200,
        },
        {
          title: "订单金额",
          props: "payableAmount",
        },
        {
          title: "优惠金额",
          props: "discountAmount",
        },
        {
          title: "实付金额",
          props: "actualPaymentAmount",
        },
        {
          title: "服务费比例",
          props: "serviceFeeRate",
          SpecialJudgment: (res) => (res !== null ? res * 100 + "%" : "0%"),
          width: 110,
        },
        {
          title: "服务费",
          props: "serviceFee",
        },
        {
          title: "申请退/改金额",
          props: "applyRefundModificationAmount",
          width: 120,
        },
        {
          title: "售后退款金额",
          props: "afterSalesRefundAmount",
          width: 120,
        },
        {
          title: "服务费退还",
          props: "serviceFeeReturn",
          width: 110,
        },
        {
          title: "公司",
          props: "companyName",
          width: 120,
        },
        {
          title: "起点站",
          props: "departureStation",
        },
        {
          title: "终点站",
          props: "destinationStation",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) =>
            this.businessTypeList.find((i) => i.value == res).label,
        },
      ],
    };
  },
  mounted() {
    this.getCompanyList();
    this.renderData1();
  },
  methods: {
    handleClick() {
      this.value = null;
      this.valuename = null;
      let day = new Date();
      let text =
        day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
      this.time = [lastTime(new Date()), text];
      if (this.tab == "1") {
        this.form1 = {
          month: moment(new Date()).format("YYYY-MM"),
          businessType: null,
          status: null,
          companyId: null,
          currentPage: 1,
          pageSize: 10,
        };
        this.renderData1();
      }
      if (this.tab == "2") {
        this.form2 = {
          startTime: "",
          endTime: "",
          companyId: null,
          businessType: null,
          currentPage: 1,
          pageSize: 10,
        };
        this.renderData2();
      }
    },
    //获取所属公司
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    queryFun1() {
      this.form1.currentPage = 1;
      this.form1.pageSize = 10;
      this.renderData1();
    },
    // 表格数据渲染
    renderData1() {
      queryBusinessStatisticsPage(this.form1).then((res) => {
        this.tableData1 = res.data.list;
        this.total = res.data.total;
      });
    },
    // 点击导出按钮
    tableExport1() {
      let obj = this.deepClone(this.form1);
      delete obj.currentPage;
      delete obj.pageSize;
      exportBusinessStatistics(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "订单结算 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    getGroupId1(v) {
      if (v == null) {
        this.form1.companyId = null;
      } else {
        this.form1.companyId = v;
      }
    },
    // 表格详情
    tableDetailPopup(scopeRow) {
      this.rowDataDetail = scopeRow;
      this.agent = scopeRow.confirmUser;
      this.agent2 = scopeRow.settlementUser;
      this.Company = scopeRow.companyName;
      this.ticketShow = true;
    },
    // 点击确认按钮
    confirmFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "是否确认？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          businessStatisticsConfirm({ id: scope.id }).then((res) => {
            if (res.code == 1000 && res.data == true) {
              this.renderData1();
              this.$message.success("确认成功");
            }
          });
        })
        .catch(() => {});
    },
    // 点击结算操作
    SettlementFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "确认结算？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          businessStatisticsSettlement({ id: scope.id }).then((res) => {
            if (res.code == 1000 && res.data == true) {
              this.renderData1();
              this.$message.success("结算成功");
            }
          });
        })
        .catch(() => {});
    },
    onCurrentChange1(page) {
      this.form1.currentPage = page;
      this.renderData1();
    },
    onSizeChange1(size) {
      this.form1.pageSize = size;
      this.form1.currentPage = 1;
      this.renderData1();
    },

    queryFun2() {
      this.form2.currentPage = 1;
      this.form2.pageSize = 10;
      this.renderData2();
    },
    // 表格数据渲染2
    renderData2() {
      this.form2.startTime = this.time[0] + " 00:00:00";
      this.form2.endTime = this.time[1] + " 23:59:59";
      queryBusinessDetailsPage(this.form2).then((res) => {
        this.tableData2 = res.data.list;
        this.total = res.data.total;
      });
    },
    // 点击导出按钮
    tableExport2() {
      this.form2.startTime = this.time[0] + " 00:00:00";
      this.form2.endTime = this.time[1] + " 23:59:59";
      let obj = this.deepClone(this.form2);
      delete obj.currentPage;
      delete obj.pageSize;
      exportBusinessDetails(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "经营明细 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    getGroupId2(v) {
      if (v == null) {
        this.form2.companyId = null;
      } else {
        this.form2.companyId = v;
      }
    },
    onCurrentChange2(page) {
      this.form2.currentPage = page;
      this.renderData2();
    },
    onSizeChange2(size) {
      this.form2.pageSize = size;
      this.form2.currentPage = 1;
      this.renderData2();
    },
  },
};
</script>
<style lang="scss" scoped>
.businessSettlement {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/.el-range-separator {
    line-height: 26px !important;
  }
  /deep/.el-date-editor.el-input__inner {
    width: 13vw !important;
  }
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .table {
    margin-top: 10px;
  }
}
</style>
